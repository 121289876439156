<template>
  <div class="standard-block-page">
    <BlocksContentArea
      v-for="(block, index) in blocks"
      :key="
        'StandardBlockPage-' +
        block?.ContentLink?.Expanded?.__typename +
        '-' +
        index
      "
      :block="block?.ContentLink?.Expanded"
      :data-epi-edit="'MainContent'"
    />
  </div>
</template>
<script setup lang="ts">
import { type GeofenceRedirectPageFragment } from "#gql";

const props = defineProps<{
  page: GeofenceRedirectPageFragment;
  isMobile: boolean;
  isEditMode: boolean;
  isTablet: boolean;
}>();

onMounted(() => {
  if (!props.isEditMode) {
    setTimeout(() => {
      window.location.href = props.page.RedirectUrl!;
    }, (props.page.RedirectDelayInSeconds ?? 10) * 1000);
  }
});

const blocks = computed(() => {
  return (
    props.page?.MainContent?.filter(
      (block: any) => block?.ContentLink?.Expanded !== null
    ) || []
  );
});
</script>
  